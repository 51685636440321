import { LogEvent } from '@analytics';
import { Banner } from 'design-system';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from 'tailwind-config';

import { Lottie } from '@components';

interface SliderContentProps {
  body: string;
  src: string;
  url: string;
}

const HomeCTAContents: SliderContentProps[] = [
  {
    body: '전국 운전학원\n예약하기',
    src: '/home/academy-cta.svg',
    url: '/explore',
  },
  {
    body: '방문 운전연수\n신청하기',
    src: '/home/training-cta.json',
    url: '/training/reservation',
  },
];

export const HomeBoarding = () => {
  return (
    <>
      <div className="flex justify-between gap-10 p-16 pb-10">
        {HomeCTAContents.map((content, index) => {
          const { body, src, url } = content;
          return (
            <motion.div
              whileTap={{ scale: 0.97 }}
              key={index}
              className={cn(
                'text-new-Section-Title flex-1 rounded-3xl p-20',
                index === 0 ? 'bg-[#FFFCA5]' : 'bg-new-white',
              )}
            >
              <Link
                key={index}
                onClick={() => {
                  LogEvent.활성화.homeCTA('홈 CTA 버튼 : ' + url);
                }}
                href={url}
              >
                <div className="flex flex-col gap-8">
                  <div className="flex items-start justify-between">
                    <div className="text-new-Section-Title break-keep">
                      {body.split('\n').map((text, index) => (
                        <span key={'span-' + index}>
                          {text}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="self-end">
                    {src.includes('.json') ? (
                      <Lottie
                        path={src}
                        size={{
                          width: 64,
                          height: 64,
                        }}
                      />
                    ) : (
                      <Image src={src} alt={body} width={64} height={64} />
                    )}
                  </div>
                </div>
              </Link>
            </motion.div>
          );
        })}
      </div>
      <motion.div className="px-16 pb-40" whileTap={{ scale: 0.97 }}>
        <Link
          href="/event/recommend"
          onClick={() => {
            LogEvent.활성화.homeCTA('학원 추천');
          }}
        >
          <Banner
            size="medium"
            type="title+description"
            description="딱 맞는 학원을 추천해 드릴게요!"
            title="어느 학원이 좋을지 모르겠다면?"
            image="/home/academy-recommend-new.png"
            alt="운전선생 학원 추천 배너"
            backgroundColor="white"
          />
        </Link>
      </motion.div>
    </>
  );
};
